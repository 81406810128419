//Is this the region, this the soil, the clime - said then the lost Archangel -. This the seat that we must change for heaven; this mournful gloom for that celestial light?

import logo from './logo.svg';
import './App.css';

import React, { useState } from 'react';
import { signInWithEmailAndPassword, signInWithPopup, OAuthProvider } from 'firebase/auth';
import { auth } from './firebase';
import { NavLink, useNavigate } from 'react-router-dom'
import { Button } from 'react-bootstrap';
import ReactDOM from "react-dom/client";
import { useEffect } from 'react';
import jsbarcode, { code39, canvasRenderer } from 'jsbarcode';

import Accordion from 'react-bootstrap/Accordion';
import { Row, Col, Container } from 'react-bootstrap';
import html2canvas from 'html2canvas';
//const XlsxPopulate = require('xlsx-populate');
import XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import { columnNameToNumber } from 'xlsx-populate/lib/addressConverter';
import { FirebaseError } from 'firebase/app';
import axios from 'axios';
import ReactBarcode from 'react-jsbarcode';
import { editableInputTypes } from '@testing-library/user-event/dist/utils';


function base64ToExcelImage(base64Image) {
  const image = XlsxPopulate.ImageBase64.fromBase64(base64Image);
  return image;
}


function App() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [pagina, setPagina] = useState("login");
  const [showFooterImage, setShowFooterImage] = useState(false);
  const telOptions = ["Algeria (+213)", "Andorra (+376)", "Angola (+244)", "Anguilla (+1264)", "Antigua & Barbuda (+1268)", "Argentina (+54)", "Armenia (+374)", "Aruba (+297)", "Australia (+61)", "Austria (+43)", "Azerbaijan (+994)", "Bahamas (+1242)", "Bahrain (+973)", "Bangladesh (+880)", "Barbados (+1246)",
    "Belarus (+375)", "Belgium (+32)", "Belize (+501)", "Benin (+229)", "Bermuda (+1441)", "Bhutan (+975)", "Bolivia (+591)", "Bosnia Herzegovina (+387)", "Botswana (+267)", "Brazil (+55)", "Brunei (+673)", "Bulgaria (+359)", "Burkina Faso (+226)", "Burundi (+257)", "Cambodia (+855)", "Cameroon (+237)",
    "Canada (+1)", "Cape Verde Islands (+238)", "Cayman Islands (+1345)", "Central African Republic (+236)", "Chile (+56)", "China (+86)", "Colombia (+57)", "Comoros (+269)", "Congo (+242)", "Cook Islands (+682)", "Costa Rica (+506)", "Croatia (+385)", "Cuba (+53)", "Cyprus North (+90392)", "Cyprus South (+357)",
    "Czech Republic (+42)", "Denmark (+45)", "Djibouti (+253)", "Dominica (+1809)", "Dominican Republic (+1809)", "Ecuador (+593)", "Egypt (+20)", "El Salvador (+503)", "Equatorial Guinea (+240)", "Eritrea (+291)", "Estonia (+372)", "Ethiopia (+251)", "Falkland Islands (+500)",
    "Faroe Islands (+298)", "Fiji (+679)", "Finland (+358)", "France (+33)", "French Guiana (+594)", "French Polynesia (+689)", "Gabon (+241)", "Gambia (+220)", "Georgia (+7880)", "Germany (+49)", "Ghana (+233)", "Gibraltar (+350)", "Greece (+30)", "Greenland (+299)", "Grenada (+1473)",
    "Guadeloupe (+590)", "Guam (+671)", "Guatemala (+502)", "Guinea (+224)", "Guinea - Bissau (+245)", "Guyana (+592)", "Haiti (+509)", "Honduras (+504)", "Hong Kong (+852)", "Hungary (+36)", "Iceland (+354)", "India (+91)", "Indonesia (+62)", "Iran (+98)", "Iraq (+964)", "Ireland (+353)", "Israel (+972)",
    "Italy (+39)", "Jamaica (+1876)", "Japan (+81)", "Jordan (+962)", "Kazakhstan (+7)", "Kenya (+254)", "Kiribati (+686)", "Korea North (+850)", "Korea South (+82)", "Kuwait (+965)", "Kyrgyzstan (+996)", "Laos (+856)", "Latvia (+371)", "Lebanon (+961)", "Lesotho (+266)", "Liberia (+231)",
    "Libya (+218)", "Liechtenstein (+417)", "Lithuania (+370)", "Luxembourg (+352)", "Macao (+853)", "Macedonia (+389)", "Madagascar (+261)", "Malawi (+265)", "Malaysia (+60)", "Maldives (+960)", "Mali (+223)", "Malta (+356)", "Marshall Islands (+692)", "Martinique (+596)", "Mauritania (+222)", "Mayotte (+269)",
    "Mexico (+52)", "Micronesia (+691)", "Moldova (+373)", "Monaco (+377)", "Mongolia (+976)", "Montserrat (+1664)", "Morocco (+212)", "Mozambique (+258)", "Myanmar (+95)", "Namibia (+264)", "Nauru (+674)", "Nepal (+977)", "Netherlands (+31)", "New Caledonia (+687)", "New Zealand (+64)", "Nicaragua (+505)",
    "Niger (+227)", "Nigeria (+234)", "Niue (+683)", "Norfolk Islands (+672)", "Northern Marianas (+670)", "Norway (+47)", "Oman (+968)", "Palau (+680)", "Panama (+507)", "Papua New Guinea (+675)", "Paraguay (+595)", "Peru (+51)", "Philippines (+63)", "Poland (+48)", "Portugal (+351)", "Puerto Rico (+1787)",
    "Qatar (+974)", "Reunion (+262)", "Romania (+40)", "Russia (+7)", "Rwanda (+250)", "San Marino (+378)", "Sao Tome & Principe (+239)", "Saudi Arabia (+966)", "Senegal (+221)", "Serbia (+381)", "Seychelles (+248)", "Sierra Leone (+232)", "Singapore (+65)", "Slovak Republic (+421)", "Slovenia (+386)",
    "Solomon Islands (+677)", "Somalia (+252)", "South Africa (+27)", "Sri Lanka (+94)", "St. Helena (+290)", "St. Kitts (+1869)", "St. Lucia (+1758)", "Sudan (+249)", "Suriname (+597)", "Swaziland (+268)", "Sweden (+46)", "Switzerland (+41)", "Syria (+963)", "Taiwan (+886)", "Tajikstan (+7)",
    "Thailand (+66)", "Togo (+228)", "Tonga (+676)", "Trinidad & Tobago (+1868)", "Tunisia (+216)", "Turkey (+90)", "Turkmenistan (+7)", "Turkmenistan (+993)", "Turks & Caicos Islands (+1649)", "Tuvalu (+688)", "Uganda (+256)", "UK (+44)", "Ukraine (+380)", "United Arab Emirates (+971)", "Uruguay (+598)",
    "USA (+1)", "Uzbekistan (+7)", "Vanuatu (+678)", "Vatican City (+379)", "Venezuela (+58)", "Vietnam (+84)", "Virgin Islands - British (+1284)", "Virgin Islands - US (+1340)", "Wallis & Futuna (+681)", "Yemen (North)(+969)", "Yemen (South)(+967)", "Zambia (+260)", "Zimbabwe (+263)"];

  const paisOptions = ["Afganistán (004)", "Albania (008)", "Andorra (020)", "Angola (024)", "Anguila (660)", "Antigua y Barbuda (028)", "Arabia Saudita (682)", "Argelia (012)", "Argentina (032)", "Armenia (051)", "Aruba (533)", "Australia (036)", "Austria (040)", "Azerbaiyán (031)", "Belgium (056)", "Bahamas (044)", "Bahrein (048)", "Bangladesh (050)", "Barbados (052)", "Belice (084)", "Benín (204)", "Bhután (064)", "Bielorrusia (112)", "Bolivia (068)", "Bosnia y Herzegovina (070)", "Botsuana (072)", "Brasil (076)", "Brunéi (096)", "Bulgaria (100)", "Burkina Faso (854)", "Burundi (108)", "Cabo Verde (132)", "Camboya (116)", "Camerún (120)", "Canadá (124)", "Chad (148)", "Chile (152)", "China (156)", "Chipre (196)", "Ciudad del Vaticano (336)", "Colombia (170)", "Comoras (174)", "Republic of the Congo (178)", "Democratic Republic of the Congo (180)", "Corea del Norte (410)", "Corea del Sur (408)", "Costa de Marfil (384)", "Costa Rica (188)", "Croacia (191)", "Cuba (192)", "Denmark (208)", "Dominica (212)", "Ecuador (218)", "Egipto (818)", "El Salvador (222)", "United Arab Emirates (784)", "Eritrea (232)", "Eslovaquia (703)", "Eslovenia (705)", "España (724)", "Estonia (233)", "Etiopía (231)", "Filipinas (608)", "Finlandia (246)", "Fiyi (242)", "France (242)", "Gabón (266)", "Gambia (270)", "Georgia (268)", "Germany (276)", "Ghana (288)", "Gibraltar (292)", "Granada (308)", "Grecia (300)", "Groenlandia (304)", "Guadalupe (312)", "Guam (316)", "Guatemala (320)", "Guayana Francesa (254)", "Guernsey (831)", "Guinea (324)", "Guinea Ecuatorial (226)", "Guinea-Bissau (624)", "Guyana (328)", "Haití (332)", "Honduras (340)", "Hong kong (344)", "Hungría (348)", "India (356)",
    "Indonesia (360)", "Irán (364)", "Irak (368)", "Ireland (372)", "Isla de Man (833)", "Isla Norfolk (574)", "Islandia (352)", "Islas Bermudas (060)", "Islas Caimán (136)", "Islas Cook (184)", "Islas de Åland (248)", "Islas Feroe (234)", "Islas Maldivas (462)", "Islas Malvinas (238)", "Islas Marianas del Norte (580)", "Islas Marshall (584)", "Islas Pitcairn (612)", "Islas Salomón (090)", "Islas Turcas y Caicos (796)", "Islas Ultramarinas Menores de Estados Unidos (581)", "Islas Vírgenes Británicas (092)", "Islas Vírgenes de los Estados Unidos (850)", "Israel (376)", "Italia (380)", "Jamaica (388)", "Japan (392)", "Jersey (832)", "Jordania (400)", "Kazajistán (398)", "Kenia (404)", "Kirguistán (417)", "Kiribati (296)", "Kuwait (414)", "Lebanon (422)", "Laos (418)", "Lesoto (426)", "Letonia (428)", "Liberia (430)", "Libia (434)", "Liechtenstein (438)", "Lituania (440)", "Luxemburgo (442)", "México (484)", "Mónaco (492)", "Macao (446)", "Macedônia (807)", "Madagascar (450)", "Malasia (458)", "Malawi (454)", "Mali (466)", "Malta (470)", "Marruecos (504)", "Martinica (474)", "Mauricio (480)", "Mauritania (478)", "Mayotte (175)", "Micronesia (583)", "Moldavia (498)", "Mongolia (496)", "Montenegro (499)", "Montserrat (500)", "Mozambique (508)", "Myanmar (104)", "Namibia (516)", "Nauru (520)", "Nepal (524)", "Netherlands (528)", "Nicaragua (558)", "Niger (562)",
    "Nigeria (566)", "Niue (570)", "Norway (578)", "Nueva Caledonia (540)", "Nueva Zelanda (554)", "Omán (512)", "Pakistán (586)", "Palau (585)", "Palestina (970)", "Panamá (591)", "Papúa Nueva Guinea (598)", "Paraguay (600)", "Perú (604)", "Polinesia Francesa (258)", "Poland (616)", "Portugal (620)", "Puerto Rico (630)", "Qatar (634)", "República Centroafricana (140)", "República Checa (203)", "República Dominicana (214)", "República de Sudán del Sur (728)", "Reunión (638)", "Ruanda (646)", "Romania (642)", "Rusia (643)", "Sahara Occidental (732)", "Samoa (882)", "Samoa Americana (016)", "San Marino (674)", "San Martín (Francia) (663)", "San Pedro y Miquelón (666)", "Santa Elena (654)", "Santa Lucía (662)", "Santo Tomé y Príncipe (678)", "Senegal (686)", "Serbia (688)", "Seychelles (690)", "Sierra Leona (694)", "Singapore (702)", "Sint Maarten (663)", "Siria (760)", "Somalia (706)", "Sri lanka (144)", "Sudáfrica (710)", "Sudán (736)", "Sweden (578)", "Switzerland (756)", "Surinám (740)", "Swazilandia (748)", "Tayikistán (762)", "Tailandia (764)", "Taiwán (886)", "Tanzania (834)", "Timor Oriental (626)", "Togo (768)", "Tokelau (772)", "Tonga (776)", "Trinidad y Tobago (780)", "Tunez (788)", "Turkmenistán (795)", "Turquía (792)", "Tuvalu (798)",
    "Ucrania (804)", "Uganda (800)", "UK (826)", "Uruguay (858)", "USA (840)", "Uzbekistán (840)", "Vanuatu (548)", "Venezuela (862)", "Vietnam (704)", "Wallis y Futuna (876)", "Yemen (887)", "Yibuti (262)", "Zambia (894)", "Zimbabue (716)"];

  const idiomaOptions = ["Català", "Castellano", "English"];

  const genereOptions = ["Masculí", "Femení", "No-binario"];
  const documentOptions = ["DNI", "Pasaport", "Altres"];


  const versioSoftware = "3.3";
  
  const URL_DOMAIN = 'https://portalapi.appsescac.com/'; //'https://portalapi.appsescac.com/'//'http://127.0.0.1:3001/';
  const URL_EMAIL_DEBUG = '?email=christian.checa@escac.es'; //christian.checa@escac.es
  const URL_EMAIL_PRODUCTION = '?email=';
  const DEBUG = 0; //a la api, comentar les dues línies del cors per producció i descomentar-les per fer proves en local

  var globalLlistaNotesAlumnes = "";



  const [idioma, setLlengua] = useState('');
  //http://127.0.0.1:3001/expedient?email=luis.contreras@escac.com  
  //https://portalapi.appsescac.com/expedient?email=luis.contreras@escac.com   
  //https://portalapi.appsescac.com/expedient?email='+sessionStorage.getItem('email')



  const onLogin = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        sessionStorage.setItem('email', userCredential.user.email);
        changePagina("expedient");
        //window.location.href = 'expedient'
        console.log(userCredential.user.email);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage)
      });
  }

  const handleMicrosoftLogin = (e) => {
    e.preventDefault();
    //setLoader(prevState => ({ ...prevState, microsoftLoading: true }));
    const provider = new OAuthProvider('microsoft.com').setCustomParameters({ tenant: 'escaces.onmicrosoft.com' });

    signInWithPopup(auth, provider)
      .then(data => {
        console.log(data);
        console.log(data.user.email);
        sessionStorage.setItem('email', data.user.email);

        changePagina("expedient");

        //window.location.href = 'expedient'
        //setLoader(prevState => ({ ...prevState, microsoftLoading: false }));
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage)
      });
  }



  const changePagina = (pagina) => {
    setPagina(pagina);
  }

  const desarNotes = () => {
    const elements = document.querySelectorAll('[id^="idNotaModificada_"]');
    if (elements.length > 0) {
      var loadingDots = document.getElementById("loading_div2");
      loadingDots.style.visibility = "visible";
      console.log("DESAAAAAR NOTEEEEEES");
      for (let i = 0; i < elements.length; i++) {
        setTimeout(function () {
          console.log("eleeeeem " + elements[i].id + " " + elements[i].innerHTML + " " + (new Date().getTime() / 1000));
          const rowTds = Array.from(elements[i].closest('tr').querySelectorAll('td'));
          console.log("rowTds[0]" + rowTds[0].innerHTML + " " + rowTds[5].innerHTML);
          console.log("rowTds[1]" + rowTds[0].innerHTML + " " + rowTds[6].innerHTML);
          modificarNota(rowTds[0].innerHTML, rowTds[3].innerHTML, rowTds[5].innerHTML, rowTds[6].innerHTML);
          elements[i].removeAttribute('id');
          if (i == elements.length - 1)
            loadingDots.style.visibility = "hidden";
        }, i * 500);
      }
    }
  }


  


  const show_cursActual = (e) => {
    document.getElementById('contenidor1').style.display = 'block';
    document.getElementById('contenidor2').style.display = 'none';
    document.getElementById('contenidor3').style.display = 'none';
    document.getElementById('boto_cursActual').style.backgroundColor = '#dddddd';
    document.getElementById('boto_cursosAnteriors').style.backgroundColor = '#ffffff';
  }

  const show_cursosAnteriors = (e) => {
    document.getElementById('contenidor1').style.display = 'none';
    document.getElementById('contenidor2').style.display = 'block';
    document.getElementById('contenidor3').style.display = 'block';
    document.getElementById('boto_cursActual').style.backgroundColor = '#ffffff';
    document.getElementById('boto_cursosAnteriors').style.backgroundColor = '#dddddd';
  }

  const closeModal = () => {
    document.getElementById('modal_id').classList.remove('modal-overlay');
    document.getElementById('modal_id').setAttribute('hidden');
  }

  const pagarFiança = () => {
    document.getElementById('modal_id').classList.add('modal-overlay');
  };

  const drawExpedient = (progres_perc, anyActual, desdeSempre, foundation, isTeacher, faculty, nom, contactId, dni, numeroAny, niub) => {
    console.log("IS TEEEACHER " + isTeacher);
    console.log("IS FACULTYYY " + faculty);
    if (isTeacher == false) {
      console.log("he/she is student!!!");
      document.getElementById('btn_area_profes').style.visibility = 'hidden';
    }


    var front = document.getElementById("fr_img");
    var back = document.getElementById("bk_img");
    var front2 = document.getElementById("fr_img2");
    var back2 = document.getElementById("bk_img2");

    front.width = front2.width = 400;
    front.height = front2.height = 250;
    back.width = back2.width = 400;
    back.height = back2.height = 250;

    console.log("IS FACULTYYY2 " + faculty);


    if (faculty == false) {
      console.log("STUDEEENT CARNET");
      front.setAttribute("src", require("./images/student_front.png"));
      back.setAttribute("src", require("./images/student_back.png"));
      front2.setAttribute("src", require("./images/student_front.png"));
      back2.setAttribute("src", require("./images/student_back.png"));
    }
    else {
      console.log("IS FACULTYYY3 " + faculty);
      console.log("PROFEEE CARNET");
      front.setAttribute("src", require("./images/professor_front.png"));
      back.setAttribute("src", require("./images/professor_back.png"));
      front2.setAttribute("src", require("./images/professor_front.png"));
      back2.setAttribute("src", require("./images/professor_back.png"));
    }
    create_barCode(dni);
    document.getElementById("student_name").innerHTML = nom;
    document.getElementById("caducitat_val").innerHTML = numeroAny;
    document.getElementById("student_name2").innerHTML = nom;
    document.getElementById("caducitat_val2").innerHTML = numeroAny;



    let listID = [];
    listID.push(contactId);
    peticioGetPhoto(listID).then((res) => {
      console.log("22222222");
      console.log(res);
      let link = res.split(",")[0].replaceAll("\"", "").replace("[", "").replace("]", "");
      console.log("enllaç: " + link);
      if (link != undefined && link.length > 5)
        document.getElementById("img_perfil").src = URL_DOMAIN + "getDownloadPhoto?ID=" + link;


    }).catch((err) => {
      console.error(err);
    });


    var contenidor = document.getElementById('taula_percentatges');
    var table = document.createElement("table");
    table.className = "table";
    table.setAttribute('id', 'table_perc');
    for (i = 0; i < 6; i++) {
      var tr = document.createElement('tr');
      tr.setAttribute('class', 'progress-bar_tr');
      var th = document.createElement('th');
      th.setAttribute('class', 'progress-bar_th');
      th.setAttribute('id', 'progress-bar_th_' + i);
      th.hidden = true;
      var div1 = document.createElement("div");
      div1.setAttribute('class', 'progress-bar');
      var div2 = document.createElement("div");
      div2.setAttribute('class', 'progress');
      div2.setAttribute('id', 'progress_' + i);
      div2.style.width = '0%';
      var div3 = document.createElement("div");
      div3.setAttribute('class', 'progress-label');
      div3.setAttribute('id', 'progress-label_' + i);
      //div3.appendChild(document.createTextNode("20%"));
      div1.append(div2);
      div1.append(div3);
      th.append(div1);
      tr.appendChild(th);
      table.append(tr);
    }
    contenidor.append(table);

    try {
      var prog = progres_perc.split('*');
      for (let j = 0; j < prog.length; j++) {
        var assignatura = prog[j].split('|')[0];
        var passed = parseInt(prog[j].split('|')[1]);
        var remaining = parseInt(prog[j].split('|')[2]);
        var perc = (passed * 100) / (remaining + passed);
        //showPercentageTitulacio(j, perc, assignatura + " (" + Math.round(perc) + "%)");
      }
    } catch (err) {
      console.log("Error progres: " + err);
    }


    var num = 1;
    var myVariable_all = anyActual.split('¿');
    /*Actual*/
    for (let j = 0; j < myVariable_all.length; j++) {
      var myVariable = myVariable_all[j];
      console.log("anyAAA " + myVariable);
      var arrTemes = myVariable.split('*');
      var nom_alumne = document.getElementById('nom_alumne');
      nom_alumne.innerText = sessionStorage.getItem("displayName");
      document.getElementById('versio').innerText = "Versió web: " + versioSoftware;
      document.getElementById('niub_alumne').innerText = "NIUB: " + niub;
      console.log("arrTemeeees   " + arrTemes.length);
      if (arrTemes.length > 1) {
        var contenidor = document.getElementById('contenidor1');
        var table = document.createElement("table");
        table.className = "table";
        table.setAttribute('id', 'table_' + num);
        var tr = document.createElement('tr');
        var th = document.createElement('th');
        th.setAttribute('id', 'col_nomAssignatura');
        th.appendChild(document.createTextNode("Nom d'assignatura"));
        tr.appendChild(th);
        var th = document.createElement('th');
        th.setAttribute('id', 'col_nomTema');
        th.appendChild(document.createTextNode("Nom del tema"));
        tr.appendChild(th);
        var th = document.createElement('th');
        th.setAttribute('id', 'col_professor');
        th.appendChild(document.createTextNode("Professor"));
        tr.appendChild(th);
        var th = document.createElement('th');
        th.setAttribute('id', 'col_ponderacio');
        th.appendChild(document.createTextNode("Ponderació"));
        tr.appendChild(th);
        var th = document.createElement('th');
        th.setAttribute('id', 'col_email');
        th.appendChild(document.createTextNode("Email"));
        tr.appendChild(th);
        var th = document.createElement('th');
        th.setAttribute('id', 'col_grup');
        th.appendChild(document.createTextNode("Grup"));
        tr.appendChild(th);
        var th = document.createElement('th');
        th.setAttribute('id', 'col_notaTema');
        th.appendChild(document.createTextNode("Nota tema"));
        tr.appendChild(th);
        var th = document.createElement('th');
        th.setAttribute('id', 'col_notaGlobal');
        th.appendChild(document.createTextNode("Nota global"));
        tr.appendChild(th);
        var th = document.createElement('th');
        th.setAttribute('id', 'col_intent');
        th.appendChild(document.createTextNode("Intent"));
        tr.appendChild(th);

        table.appendChild(tr);

        var last = ""

        var lastAssignatura = '';
        var jumps = 0;
        for (const element of arrTemes) {
          console.log(element);
          var elems = element.split('|');

          if (typeof elems[1] == 'undefined')
            continue;

          if (elems[0] != last) {
            var last = elems[0];
            var div_grau = document.createElement("div");
            div_grau.textContent = elems[0];
            div_grau.className = "desplegable";
            div_grau.setAttribute('id', 'desplegable_' + num);
            num++;
            contenidor.appendChild(div_grau);
            console.log("eleeeeeee " + elems[0]);
          }

          var tr1 = document.createElement("tr");
          table.appendChild(tr1);

          for (let i = 1; i < elems.length; i++) {
            console.log(i + " " + jumps + " " + elems[i])
            if (i == 1 && jumps > 0) {
              jumps--;
              continue;
            }
            else if (i == elems.length - 1 && lastAssignatura == elems[1]) {
              continue;
            }

            var td = document.createElement("td");
            if (i == 1 && lastAssignatura != elems[1]) {
              var count = (myVariable.split('|' + elems[1] + '|').length - 1)
              td.rowSpan = count;
              jumps = count - 1;
              td.style.backgroundColor = "#BBB6B4";
            }
            if (i == elems.length - 1 && lastAssignatura != elems[1]) {
              td.rowSpan = jumps + 1;
              td.style.backgroundColor = "#BBB6B4";
            }

            td.innerText = elems[i];
            tr1.appendChild(td);
          }
          lastAssignatura = elems[1];
        }
        contenidor.appendChild(table);

        contenidor.appendChild(document.createElement('br'));
      }
    }


    /*Historial*/
    var myVariable_all = desdeSempre.split('¿');
    for (let j = 0; j < myVariable_all.length; j++) {
      var myVariable = myVariable_all[j];
      console.log("desdSS " + myVariable);
      var arrTemes = myVariable.split('*');

      if (arrTemes.length > 1) {
        console.log("entraaa");
        var contenidor = document.getElementById('contenidor2');
        var table = document.createElement("table");
        table.className = "table";
        table.setAttribute('id', 'table_' + num);
        var tr = document.createElement('tr');
        var th = document.createElement('th');
        th.setAttribute('id', 'col_nomAssignatura');
        th.appendChild(document.createTextNode("Nom d'assignatura"));
        tr.appendChild(th);
        var th = document.createElement('th');
        th.setAttribute('id', 'col_credits');
        th.appendChild(document.createTextNode("Crèdits"));
        tr.appendChild(th);
        var th = document.createElement('th');
        th.setAttribute('id', 'col_convocatoria');
        th.appendChild(document.createTextNode("Convocatòria"));
        tr.appendChild(th);
        var th = document.createElement('th');
        th.setAttribute('id', 'col_tipus');
        th.appendChild(document.createTextNode("Tipus"));
        tr.appendChild(th);
        var th = document.createElement('th');
        th.setAttribute('id', 'col_nota');
        th.appendChild(document.createTextNode("Nota"));
        tr.appendChild(th);
        var th = document.createElement('th');
        th.setAttribute('id', 'col_qualificacio');
        th.appendChild(document.createTextNode("Qualificació"));
        tr.appendChild(th);
        var th = document.createElement('th');
        th.setAttribute('id', 'col_resultat');
        th.appendChild(document.createTextNode("Resultat"));
        tr.appendChild(th);
        var th = document.createElement('th');
        th.setAttribute('id', 'col_curs');
        th.appendChild(document.createTextNode("Curs"));
        tr.appendChild(th);
        var th = document.createElement('th');
        th.setAttribute('id', 'col_intent');
        th.appendChild(document.createTextNode("Intent"));
        tr.appendChild(th);

        table.appendChild(tr);
        last = ""


        for (const element of arrTemes) {
          console.log(element);
          console.log();
          elems = element.split('|');

          if (typeof elems[1] == 'undefined')
            continue;

          if (elems[0] != last) {
            last = elems[0];
            var div_grau = document.createElement("div");
            div_grau.textContent = elems[0];
            div_grau.className = "desplegable";
            div_grau.setAttribute('id', 'desplegable_' + num);
            num++;
            contenidor.appendChild(div_grau);
          }

          var tr1 = document.createElement("tr");
          table.appendChild(tr1);

          for (let i = 1; i < elems.length; i++) {
            var td = document.createElement("td");

            if (i == elems.length - 1 || i == 1) {
              td.style.backgroundColor = "#BBB6B4";
            }

            td.innerText = elems[i];
            tr1.appendChild(td);
          }
        }
        contenidor.appendChild(table);
        contenidor.appendChild(document.createElement('br'));
      }
    }


    var elem = document.getElementsByClassName('desplegable');
    console.log(elem.length);
    for (var i = 0; i < elem.length; i++) {
      console.log(elem[i] + " " + i);
      elem[i].addEventListener('click', function () {
        var id = this.getAttribute('id').split('_')[1];
        console.log('id:' + id + '  tot:' + this.getAttribute('id'));
        console.log("tableee: " + i + "   " + document.getElementById('table_' + id));
        var titol = document.getElementById('table_' + id);
        titol.classList.toggle('collapsed');
      }, false);
    }


    document.getElementById('contenidor1').style.display = 'none';
    document.getElementById('contenidor2').style.display = 'none';
    document.getElementById('contenidor3').style.display = 'none';

    traduir();

    setTimeout(() => {
      if (window.innerWidth / window.innerHeight > 1.0) {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }
    }, 500);
  }


  const create_barCode = (DNI) => {
    var dni_new = DNI.replace(/[^\d.-]/g, '');
    dni_new = "00000000" + dni_new;
    dni_new = dni_new.substr(dni_new.length - 8);
    jsbarcode("#barcode", "98" + dni_new, {
      format: "code128",
      width: 6,
      fontSize: 40
    });
  }



  const descarregarCarnet = () => {
    document.getElementById('capture_carnet').hidden = false;
    html2canvas(document.querySelector("#capture_carnet"), {
      width: 300,
      height: 365
    }).then(canvas => {
      const base64image = canvas.toDataURL("image/png");
      var anchor = document.createElement("a");
      anchor.setAttribute("href", base64image);
      anchor.setAttribute("download", "carnet.png");
      anchor.click();
      anchor.remove();
    });
    document.getElementById('capture_carnet').hidden = true;
  };


  const drawProfe = (notes, llistaCollapsed, posScroll) => {
    var myVariable_all = notes.split('¿');
    globalLlistaNotesAlumnes = notes;

    var contador = 1;

    var table;
    var last = '';
    const indexTema = 2;
    const indexNota = 6;
    const indexNotaAlfa = 7;

    var originalValue = "";
    var nom_alumne = document.getElementById('nom_alumne');
    nom_alumne.innerText = sessionStorage.getItem("displayName");
    document.getElementById('versio').innerText = "Versió web: " + versioSoftware;
    var contenidor = document.getElementById('contenidor11');
    contenidor.innerHTML = '';


    for (let j = 0; j < myVariable_all.length; j++) {
      var myVariable = myVariable_all[j];
      console.log("NOTA processing " + myVariable);
      if (myVariable.length > 1) {
        var elems = myVariable.split("|");
        var contenidor = document.getElementById('contenidor11');
        if (elems[indexTema] != last) {
          last = elems[indexTema];


          const button = document.createElement("img");
          button.src = "/static/media/xls.10f985122116f274c5e7.png";
          button.width = 32;
          button.height = 32;
          button.className = "buttonToggle";
          button.setAttribute("id", last);
          button.addEventListener("mouseover", function () {
            this.style.transform = 'scale(1.2)';
            this.style.transitionDuration = "1s";
          });
          button.addEventListener("mouseout", function () {
            this.style.transform = 'scale(1)';
            this.style.transitionDuration = "1s";
          });
          button.addEventListener("click", function () {
            const promise = ferDownloadExcel(globalLlistaNotesAlumnes, this.getAttribute("id"));
          });


          const enllaç = document.createElement("a");
          enllaç.href = "https://www.openoffice.org/es/descargar/index.html"; // Make sure to include the protocol (http or https)
          enllaç.innerText = "Descarregar OpenOffice";
          enllaç.className = "aToggle";
          enllaç.setAttribute("id", "a_descarregar_" + last);
          enllaç.setAttribute("target", "_blank"); // This will open the link in a new tab
          enllaç.setAttribute("rel", "noopener noreferrer");






          table = document.createElement("table");
          table.className = "table";
          table.setAttribute('id', 'table_' + j);
          var tr = document.createElement('tr');

          var th = document.createElement('th');
          th.appendChild(document.createTextNode("Id"));
          th.style.fontSize = "0px"
          th.style.width = "0px"
          tr.appendChild(th);
          var th = document.createElement('th');
          th.appendChild(document.createTextNode("ContactId"));
          th.style.fontSize = "0px"
          th.style.width = "0px"
          tr.appendChild(th);
          var th = document.createElement('th');
          th.setAttribute('id', 'col_grup');
          th.appendChild(document.createTextNode("Grup"));
          tr.appendChild(th);
          var th = document.createElement('th');
          th.setAttribute('id', 'col_nom');
          th.appendChild(document.createTextNode("Nom alumne"));
          tr.appendChild(th);
          var th = document.createElement('th');
          th.appendChild(document.createTextNode("Email"));
          tr.appendChild(th);
          var th = document.createElement('th');
          th.setAttribute('id', 'col_nota');
          th.appendChild(document.createTextNode("Nota"));
          tr.appendChild(th);
          var th = document.createElement('th');
          th.setAttribute('id', 'col_notaAlfanumerica');
          th.appendChild(document.createTextNode("Nota alfanumèrica"));
          tr.appendChild(th);
          var th = document.createElement('th');
          th.setAttribute('id', 'col_viaAcces');
          th.appendChild(document.createTextNode("Via accés"));
          tr.appendChild(th);
          var th = document.createElement('th');
          th.setAttribute('id', 'col_foto');
          th.appendChild(document.createTextNode("Foto"));
          tr.appendChild(th);
          table.appendChild(tr);

          if (posScroll != undefined && posScroll > 0)
            window.scrollTo(0, posScroll);
          if (llistaCollapsed != undefined) {
            for (let k = 0; k < llistaCollapsed.length; k++) {
              if (llistaCollapsed[k] == j) {
                table.classList.toggle('collapsed');
                break;
              }
            }
          }
          var div_grau = document.createElement("div");
          div_grau.textContent = elems[indexTema];
          div_grau.className = "desplegable";
          div_grau.setAttribute('id', 'desplegable_' + j);
          contenidor.appendChild(div_grau);
          contenidor.appendChild(document.createElement("br"));
          contenidor.appendChild(button);
          contenidor.appendChild(document.createElement("br"));
          contenidor.appendChild(enllaç);
          contenidor.appendChild(table);
          contenidor.appendChild(document.createElement('br'));
        }

        var tr1 = document.createElement("tr");

        table.appendChild(tr1);
        for (let i = 0; i < elems.length; i++) {
          if (i == indexTema)
            continue;
          var td = document.createElement("td");
          if (i == 0 || i == 1) {
            td.style.fontSize = "0px";
            td.style.width = "0px";
            td.style.padding = "0px";
          }
          else if (i == indexNota) {
            td.contentEditable = true;
            td.addEventListener("click", function (e) {
              originalValue = this.innerHTML;
            });
            td.addEventListener("blur", function (e) {
              console.log("BLUUUUUUUUUUUUUR");
              console.log("originalValue:" + originalValue);
              var typed_str = this.innerHTML;
              typed_str = typed_str.replaceAll(".", ",");
              typed_str = typed_str.replace(/[^0-9,]/g, "");
              var valor = typed_str;
              if (typed_str.includes(",")) {
                var parts = typed_str.split(",");
                valor = parts[0];
                typed_str = typed_str.split(",")[0] + ",";
                for (let i = 1; i < parts.length; i++) {
                  typed_str += parts[i];
                }
                if (typed_str.length - valor.length > 3)
                  typed_str = typed_str.substring(0, valor.length + 3);
              }
              var val = parseInt(valor, 10);
              if (val >= 10)
                typed_str = 10;

              console.log(typed_str);
              var len = typed_str.toString().length;
              if (typed_str.toString().substring(len - 1, len) == ",") {
                typed_str = typed_str.toString().slice(0, -1);
              }
              this.innerHTML = typed_str;
              console.log(typed_str);

              var notaAlfaLabel = "";
              if (typed_str == "0,09" || typed_str == "0")
                notaAlfaLabel = "NP";
              else if (val < 5)
                notaAlfaLabel = "SS";
              else if (val < 7)
                notaAlfaLabel = "AP";
              else if (val < 9)
                notaAlfaLabel = "NT";
              else if (val >= 9 && val <= 10)
                notaAlfaLabel = "EX";
              else
                this.innerHTML = notaAlfaLabel = "-";

              document.getElementById("tdNotaAlfa_" + j).innerHTML = notaAlfaLabel;
              console.log("typed_str:" + typed_str);
              if (originalValue != typed_str && !(['-', ''].includes(originalValue) && ['-', ''].includes(typed_str))) {
                this.setAttribute('id', 'idNotaModificada_' + j);
                //document.getElementById("btn_desar_notes").disabled = false;
              }
            });
          }
          else if (i == indexNotaAlfa) {
            td.setAttribute('id', 'tdNotaAlfa_' + j);
          }
          td.innerText = elems[i];

          tr1.appendChild(td);

          if (i == 8) {
            var td1 = document.createElement("td");
            td1.style.textAlign = "center";
            var imgElement = document.createElement("img");
            imgElement.style.position = "relative";

            imgElement.addEventListener("mouseover", function () {
              this.style.transform = "scale(6) rotate(370deg)";
              this.style.transitionDuration = "1s";
              this.style.zIndex = 999 + (contador++);
            });

            imgElement.addEventListener("mouseout", function () {
              this.style.transform = "scale(1) rotate(0deg)";
              this.style.transitionDuration = "1s";
            });
            imgElement.setAttribute("id", "foto_" + elems[1]);
            imgElement.setAttribute("width", "20");
            imgElement.setAttribute("height", "30");



            let listID = [];
            listID.push(elems[1]);
            const elemValue = elems[1];
            peticioGetPhoto(listID, elemValue).then((res) => {
              var foto = document.getElementById("foto_" + elemValue);
              console.log("22222222");
              console.log(res);
              let link = res.split(",")[0].replaceAll("\"", "").replace("[", "").replace("]", "");
              console.log("enllaç: " + link);
              if (link != undefined && link.length > 5)
                foto.src = URL_DOMAIN + "getDownloadPhoto?ID=" + link;
            });
            td1.appendChild(imgElement);
            tr1.appendChild(td1);
          }
        }
      }
      traduir();
    }


    var elem = document.getElementsByClassName('desplegable');
    console.log(elem.length);
    for (var i = 0; i < elem.length; i++) {
      console.log(elem[i] + " " + i);
      elem[i].addEventListener('click', function () {
        console.log("DESPLEEE " + this.innerText);
        var id = this.getAttribute('id').split('_')[1];
        console.log('id:' + id + '  tot:' + this.getAttribute('id'));
        console.log("tableee: " + i + "   " + document.getElementById('table_' + id));
        var titol = document.getElementById('table_' + id);
        titol.classList.toggle('collapsed');

        var BTN = document.querySelectorAll(`[id="` + this.innerText + `"]`);
        console.log(BTN);
        BTN[0].classList.toggle('collapsed');

        const element = document.getElementById("a_descarregar_" + this.innerText); // Retrieve the element by ID
        console.log(element);
        //element.style.display = 'none';
        element.classList.toggle('collapsed');
      }, false);
    }
  }



  const ferPeticio = () => {
    console.log("FER PETICIOOO " + sessionStorage.getItem('email'));
    console.log("Versió del software" + versioSoftware);

    let URL_FINAL = '';
    if (DEBUG)
      URL_FINAL = URL_DOMAIN + "expedient" + URL_EMAIL_DEBUG + "&idioma=" + idioma
    else
      URL_FINAL = URL_DOMAIN + "expedient" + URL_EMAIL_PRODUCTION + sessionStorage.getItem('email') + "&idioma=" + idioma;
    console.log("URL_FINAL: " + URL_FINAL);
    return fetch(URL_FINAL)
      .then(response => response.json())
      .then(data => {
        console.log(data);
        return data;
      })
      .catch(error => {
        console.log("erroooorrrrr ferPeticio!!");
        console.error(error);
        var loadingDots = document.getElementById("loading_div");
        loadingDots.style.visibility = "hidden";
        document.getElementById("banner_resultat").removeAttribute("hidden")
        throw error;
      });
  }



  const ferPeticioProfe = () => {
    console.log("FER PETICIOOO PROFE " + sessionStorage.getItem('email'));
    console.log("Versió del software" + versioSoftware);
    let URL_FINAL = '';
    if (DEBUG)
      URL_FINAL = URL_DOMAIN + "profes" + URL_EMAIL_DEBUG + "&idioma=" + idioma;
    else
      URL_FINAL = URL_DOMAIN + "profes" + URL_EMAIL_PRODUCTION + sessionStorage.getItem('email') + "&idioma=" + idioma;
    console.log("URL_FINAL profes: " + URL_FINAL);
    return fetch(URL_FINAL)
      .then(response => response.json())
      .then(data => {
        console.log(data);
        return data;
      })
      .catch(error => {
        console.log("erroooorrrrr ferPeticioProfe!!");
        console.error(error);
        throw error;
      });
  }


  const ferDownloadExcel = (dades, nomTema) => {
    console.log("FER DOWNLOAD EXCEL " + nomTema);
    const indexTema = 2;
    var notes = dades.split("¿");
    var lastTema = "";
    var notesTema = "";
    var found = 0;
    for (let i = 0; i < notes.length; i++) {
      //console.log("nooooo " + notes[i]);
      var elem = notes[i].split("|");
      var dada = elem[1] + "|" + elem[3] + "|" + elem[4] + "|" + elem[5] + "|" + elem[6];


      if (elem[indexTema] === nomTema) {
        notesTema += dada + "¿";
        found = 1;
      }
      else if (found == 1) {
        console.log("ENVIEEM NOT : " + notesTema);
        let URL_FINAL = URL_DOMAIN + "downloadExcel?nomTema=" + nomTema + "&dades=¿" + notesTema;
        ((nomTema) => {
          var BTN = document.querySelectorAll('.buttonToggle');
          BTN.forEach(button => {
            button.style.display = "none";
          });
          //elem[i].setAttribute('class','collapsed'); AIXO NO funciona!



          axios({
            method: 'get',
            url: URL_FINAL,
            responseType: 'blob',
            headers: {},
          })
            .then((res) => {
              const url = window.URL.createObjectURL(new Blob([res.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', "notes_alumnes_" + nomTema + ".xlsx");
              document.body.appendChild(link);
              link.click();
              var BTN = document.querySelectorAll('.buttonToggle');
              BTN.forEach(button => {
                button.style.display = "block";
              });
            })
            .catch((error) => {
              var BTN = document.querySelectorAll('.buttonToggle');
              BTN.forEach(button => {
                button.style.display = "block";
              });
              alert(error);
            });
        })(nomTema);
        break;
      }



      //elem = elem[0];
      /*if (elem[indexTema] != lastTema) {
        console.log(lastTema + "<<<<<<<<<<<<>>>>>>>>>>>"+nomTema);
        if (lastTema != "" && lastTema === nomTema) {
          console.log("ENVIEEM NOT : " + notesTema);
          let URL_FINAL = URL_DOMAIN + "downloadExcel?nomTema=" + lastTema + "&dades=¿" + notesTema;
          ((lastTema) => {
            axios({
              method: 'get',
              url: URL_FINAL,
              responseType: 'blob',
              headers: {},
            })
              .then((res) => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', "notes_alumnes_" + lastTema + ".xlsx");
                document.body.appendChild(link);
                link.click();
              })
              .catch((error) => {
                alert(error);
              });
          })(lastTema);
          notesTema = dada + "¿"; //resetegem
        }
        else {
          notesTema += dada + "¿";
        }
        lastTema = elem[indexTema];
      }
      else {
        notesTema += dada + "¿";
      }*/
    }
    alert("Esperi mentre es genera l'Excel (de vegades pot tardar una mica):\n\nEn cas de no poder visualitzar les imatges a l'interior de l'Excel, es recomana instal·lar una versió més nova d'Excel o instal·lar OpenOffice Calc (és gratuït).");
  }

  const ferModificarNota = (scID, nota, notaAN) => {
    console.log("FER MODIFICAR NOTA ");
    let URL_FINAL = URL_DOMAIN + "modificarNota?scID=" + scID + "&nota=" + nota + "&notaAN=" + notaAN;
    return fetch(URL_FINAL)
      .then(data => {
        console.log(data);
        return data;
      })
      .catch(error => {
        console.log("erroooorrrrr ferModificarNota!!");
        console.error(error);
        throw error;
      });
  }

  const ferGetPhotos = (listID) => {
    console.log("FER getPhotos");
    let URL_FINAL = URL_DOMAIN + "getPhotosURLS?listID=" + listID;
    return fetch(URL_FINAL)
      .then(response => response.text()) // Retrieve the text content
      .then(text => {
        console.log("ferGetPhotos: result");
        console.log(text); // Use the text content
        return text; // Return the text content
      })
      .catch(error => {
        console.log("erroooorrrrr ferGetPhotos!!");
        console.error(error);
        throw error;
      });
  }



  const peticio = () => {

    const promise = ferPeticio();
    console.log("BEFOREE");
    promise.then((res) => {
      console.log("THENNNN");
      console.log(res);

      try {
        console.log("numeroAny " + res['numeroAny']);
        console.log("ANYY ACTUAL " + res['anyActual']);
        console.log("DESDE SEMPREE " + res['desdeSempre']);
        console.log("foundationnn " + res['foundation']);
        console.log("isTeacherrr " + res['isTeacher']);
        console.log("faculty " + res['faculty']);
        console.log("contactName " + res['contactName']);
        console.log("contactId " + res['contactId']);
        console.log("dni " + res['dni']);
        console.log("activo " + res['activo']);
        console.log("niub " + res['niub']);
      } catch (error) {
        console.error("try-catch peticio: " + error);
      }
      var loadingDots = document.getElementById("loading_div");
      loadingDots.style.visibility = "hidden";
      document.getElementById("banner_resultat").removeAttribute("hidden")
      sessionStorage.setItem('displayName', res['contactName']);

      console.log("compar " + (((/true/).test(res['faculty']) && (/false/).test(res['activo']))));
      console.log("compar2 " + ((/true/).test(res['faculty'])));
      console.log("compar3 " + ((/false/).test(res['activo'])));
      if ((res['anyActual'] === "" && (/false/).test(res['faculty'])) || ((/true/).test(res['faculty']) && (/false/).test(res['activo']))) {
        console.log("Entra eliminar carnet");
        document.getElementById("flip-card").style.display = "none";
        document.getElementById("capture_carnet").style.display = "none";
        document.getElementById("button_descarregar_carnet").style.display = "none";
        document.getElementById('btn_area_profes').style.display = "none";
      }
      drawExpedient(res['progres'], res['anyActual'], res['desdeSempre'], res['foundation'], res['isTeacher'], res['faculty'], res['contactName'], res['contactId'], res['dni'], res['numeroAny'], res['niub']);
    }).catch((err) => {
      console.error(err);
      alert("Error al carregar dades (envia un email a tic@escac.es informant del problema): " + err);
    });
  }



  const peticioProfe = (llistaCollapsed, posScroll) => {
    const promise = ferPeticioProfe();
    console.log("BEFOREE " + llistaCollapsed + "    " + posScroll);
    promise.then((res) => {
      console.log("THENNNN");
      console.log(res);

      try {
        console.log("NOTEES " + res['notes']);
      } catch (error) {
        console.error("try-catch peticio profe: " + error);
      }

      drawProfe(res['notes'], llistaCollapsed, posScroll);
      var loadingDots = document.getElementById("loading_div2");
      loadingDots.style.visibility = "hidden";
    }).catch((err) => {
      console.error(err);
      alert("Error al carregar notes (envia un email a tic@escac.es informant del problema): " + err);
    });
  }

  const modificarNota = (scID, nomAlumne, nota, nota_alfa) => {
    console.log("CALLED MODIFICAR NOTA!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
    if (nota == "-" || nota_alfa == "")
      return;
    nota = nota.replace(",", ".");
    console.log("scID:" + scID + " nota:" + nota + " nota_alfa:" + nota_alfa);
    const promise = ferModificarNota(scID, nota, nota_alfa);
    console.log("BEFOREE nota");
    promise.then((res) => {
      console.log("THENNNN nota");
      console.log(res);
      console.log("res.status:" + res.status);
      if (!res.ok)
        alert("Hi ha hagut un error al modificar la nota (" + nota + ") de l'alumne " + nomAlumne);
    }).catch((err) => {
      console.error(err);
      alert(err);
    });
  }

  const peticioGetPhoto = (listID) => {
    const promise = ferGetPhotos(listID);
    console.log("BEFOREE");
    return promise.then((res) => {
      console.log("THENNNN peticioGetPhoto");
      console.log(res);
      return res;
    }).catch((err) => {
      console.error(err);
    });
  }

  const openLink = () => {
    window.open("https://www.openoffice.org/es/descargar/index.html", "_blank", "noopener,noreferrer");
  };

  function traduir() {
    const elements = document.querySelectorAll('*');

    elements.forEach(element => {
      element.childNodes.forEach(node => {
        if (node.nodeType === Node.TEXT_NODE) {
          console.log("idioma " + idioma);
          if (element.id === 'btn_login') { //Botó login
            if (idioma === 'ca')
              node.nodeValue = 'Login amb Microsoft';
            else if (idioma === 'es')
              node.nodeValue = 'Login con Microsoft';
            else
              node.nodeValue = 'Login with Microsoft';
          }
          else if (element.id === 'btn_area_profes') { //Botó àrea profes
            if (idioma === 'ca')
              node.nodeValue = 'Àrea de professors';
            else if (idioma === 'es')
              node.nodeValue = 'Área de profesores';
            else
              node.nodeValue = 'Teachers area';
          }
          else if (element.id === 'versio') { //Versió web
            if (idioma === 'ca')
              node.nodeValue = 'Versió web: ' + versioSoftware;
            else if (idioma === 'es')
              node.nodeValue = 'Versión web: ' + versioSoftware;
            else
              node.nodeValue = 'Web version: ' + versioSoftware;
          }
          else if (element.id === 'boto_cursActual') { //Botó curs actual
            if (idioma === 'ca')
              node.nodeValue = 'CURS ACTUAL';
            else if (idioma === 'es')
              node.nodeValue = 'CURSO ACTUAL';
            else
              node.nodeValue = 'CURRENT COURSE';
          }
          else if (element.id === 'boto_cursosAnteriors') { //Botó curs anterior
            if (idioma === 'ca')
              node.nodeValue = 'CURSOS ANTERIORS';
            else if (idioma === 'es')
              node.nodeValue = 'CURSOS ANTERIORES';
            else
              node.nodeValue = 'PREVIOUS COURSES';
          }
          else if (element.id === 'col_nomAssignatura') { //Columna nom assignatura
            if (idioma === 'ca')
              node.textContent = "Nom d'assignatura";
            else if (idioma === 'es')
              node.textContent = 'Nombre de asignatura';
            else
              node.textContent = 'Subject name';
          }
          else if (element.id === 'col_nomTema') { //Columna nom tema
            if (idioma === 'ca')
              node.textContent = "Nom del tema";
            else if (idioma === 'es')
              node.textContent = 'Nombre del tema';
            else
              node.textContent = 'Theme name';
          }
          else if (element.id === 'col_professor') { //Columna professor
            if (idioma === 'ca')
              node.textContent = "Professor";
            else if (idioma === 'es')
              node.textContent = 'Profesor';
            else
              node.textContent = 'Teacher';
          }
          else if (element.id === 'col_ponderacio') { //Columna ponderació
            if (idioma === 'ca')
              node.textContent = "Ponderació";
            else if (idioma === 'es')
              node.textContent = 'Ponderación';
            else
              node.textContent = 'Weighting';
          }
          else if (element.id === 'col_grup') { //Columna grup
            if (idioma === 'ca')
              node.textContent = "Grup";
            else if (idioma === 'es')
              node.textContent = 'Grupo';
            else
              node.textContent = 'Group';
          }
          else if (element.id === 'col_notaTema') { //Columna nota tema
            if (idioma === 'ca')
              node.textContent = "Nota tema";
            else if (idioma === 'es')
              node.textContent = 'Nota tema';
            else
              node.textContent = 'Theme grade';
          }
          else if (element.id === 'col_notaGlobal') { //Columna nota global
            if (idioma === 'ca')
              node.textContent = "Nota global";
            else if (idioma === 'es')
              node.textContent = 'Nota global';
            else
              node.textContent = 'Global grade';
          }
          else if (element.id === 'col_intent') { //Columna intent
            if (idioma === 'ca')
              node.textContent = "Intent";
            else if (idioma === 'es')
              node.textContent = 'Intento';
            else
              node.textContent = 'Attempt';
          }
          else if (element.id === 'col_credits') { //Columna crèdits
            if (idioma === 'ca')
              node.textContent = "Crèdits";
            else if (idioma === 'es')
              node.textContent = 'Creditos';
            else
              node.textContent = 'Credits';
          }
          else if (element.id === 'col_convocatoria') { //Columna convocatòria
            if (idioma === 'ca')
              node.textContent = "Convocatòria";
            else if (idioma === 'es')
              node.textContent = 'Convocatoria';
            else
              node.textContent = 'Call';
          }
          else if (element.id === 'col_tipus') { //Columna tipus
            if (idioma === 'ca')
              node.textContent = "Tipus";
            else if (idioma === 'es')
              node.textContent = 'Tipo';
            else
              node.textContent = 'Type';
          }
          else if (element.id === 'col_nota') { //Columna nota
            if (idioma === 'ca')
              node.textContent = "Nota";
            else if (idioma === 'es')  
              node.textContent = 'Nota';
            else
              node.textContent = 'Grade';
          }
          else if (element.id === 'col_qualificacio') { //Columna qualificació
            if (idioma === 'ca')
              node.textContent = "Qualificació";
            else if (idioma === 'es')
              node.textContent = 'Qualificación';
            else
              node.textContent = 'Qualification';
          }
          else if (element.id === 'col_resultat') { //Columna resultat
            if (idioma === 'ca')
              node.textContent = "Resultat";
            else if (idioma === 'es')
              node.textContent = 'Resultado';
            else
              node.textContent = 'Result';
          }
          else if (element.id === 'col_curs') { //Columna curs
            if (idioma === 'ca')
              node.textContent = "Curs";
            else if (idioma === 'es')
              node.textContent = 'Curso';
            else
              node.textContent = 'Course';
          }
          //ÀREA PROFES
          else if (element.id === 'btn_area_alumnes') { //Botó tornar expedient
            if (idioma === 'ca')
              node.textContent = "Tornar a l'expedient";
            else if (idioma === 'es')
              node.textContent = 'Volver al expediente';
            else
              node.textContent = 'Back to records';
          }
          else if (element.id === 'btn_desar_notes') { //Botó desar notes
            if (idioma === 'ca')
              node.textContent = "Desar notes";
            else if (idioma === 'es')
              node.textContent = 'Guardar notas';
            else
              node.textContent = 'Saves records';
          }
          else if (element.id.includes("a_descarregar_")) { //enllaç openoffice
            if (idioma === 'ca')
              node.textContent = "Descarregar OpenOffice (gratis)";
            else if (idioma === 'es')
              node.textContent = 'Descargar OpenOffice (gratis)';
            else
              node.textContent = 'Download OpenOffice (for free)';
          }
          else if (element.id === 'col_nom') { //nom alumne
            if (idioma === 'ca')
              node.textContent = "Nom alumne";
            else if (idioma === 'es')
              node.textContent = 'Nombre alumno';
            else
              node.textContent = 'Student name';
          }
          else if (element.id === 'col_notaAlfanumerica') { //nota alfanumèrica
            if (idioma === 'ca')
              node.textContent = "Nota alfanumèrica";
            else if (idioma === 'es')
              node.textContent = 'Nota alfanumérica';
            else
              node.textContent = 'Alphanumeric grade';
          }
          else if (element.id === 'col_viaAcces') { //Via accés
            if (idioma === 'ca')
              node.textContent = "Via accés";
            else if (idioma === 'es')
              node.textContent = 'Vía acceso';
            else
              node.textContent = 'Access channel';
          }
          else if (element.id === 'col_foto') { //Foto
            if (idioma === 'ca')
              node.textContent = "Foto";
            else if (idioma === 'es')
              node.textContent = 'Foto';
            else
              node.textContent = 'Picture';
          }

        }
      });
    });
  }





  const [selectedPrefix, setSelectedPrefix] = useState('');
  const handleSelectPrefixChange = (event) => {
    setSelectedPrefix(event.target.value);
  };

  const [selectedIdioma, setSelectedIdioma] = useState('');
  const handleSelectIdiomaChange = (event) => {
    setSelectedIdioma(event.target.value);
  };

  const [selectedGenere, setSelectedGenere] = useState('');
  const handleSelectGenereChange = (event) => {
    setSelectedGenere(event.target.value);
  };

  const [selectedNacionalitat, setSelectedNacionalitat] = useState('');
  const handleSelectNacionalitatChange = (event) => {
    setSelectedNacionalitat(event.target.value);
  };

  const [selectedPaisNaixement, setSelectedPaisNaixement] = useState('');
  const handleSelectPaisNaixementChange = (event) => {
    setSelectedPaisNaixement(event.target.value);
  };

  const [selectedTipusDocument, setSelectedTipusDocument] = useState('');
  const handleSelectTipusDocumentChange = (event) => {
    setSelectedTipusDocument(event.target.value);
  };



  return (
    <>
      <main>
        <section>
          {pagina == "login" &&
            <div className="container mt-5" style={{ width: '100vw', height: '100vh' }}>
              <div className="row justify-content-center">
                <div className="col-md-6 col-lg-5">
                  <div className="card shadow-lg border-0 rounded-lg mt-5">
                    <div className="card-header">
                      <h3 className="text-center font-weight-light my-4" style={{ height: '30px' }} hidden>Login</h3>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} className="mb-3">
                      <div className="form-floating w-50 mb-4"> {/* Espai entre el select i el botó */}
                        <select className="form-select btn btn-secondary btn-lg w-100" id="language-select" onChange={(e) => { document.getElementById('btn_login').hidden = false; setLlengua(e.target.value.trim()); traduir(); }}>
                          <option value="" disabled selected>Tria la llengua</option>
                          <option value="ca">Català</option>
                          <option value="es">Castellano</option>
                          <option value="en">English</option>
                        </select>
                      </div>
                      <br />
                      <div className="d-grid">
                        <button
                          id="btn_login"
                          className="btn btn-secondary btn-lg"
                          title="Fes click aquí per fer login amb el teu compte de Microsoft"
                          onClick={handleMicrosoftLogin}
                          hidden
                        >
                          Login amb Microsoft
                        </button>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <br /><br />
              <img id="footer_resultat_login" src={require("./images/footer.jpg")} alt="footer"></img>
            </div>
          }

          

          {(pagina == "expedient" || pagina == "modificar_perfil") &&
            <div id="expedient_div" className="container mt-5" style={{ width: '100vw', height: '100vh' }}>

              <img id="banner_resultat" src={require("./images/banner.jpg")} alt="banner" hidden></img>
              <br /><br />

              


              {(1 == 1) && 
                <div id="modal_id" hidden>
                  <div className="modal-content">
                    <button className="close-button" onClick={closeModal}>
                      &times; {/* This represents the close cross */}
                    </button>
                    <iframe
                      title="Example Iframe"
                      src="https://pagaments.appsescac.com/index.php?preu=100&text_info=Trametre%20pagament&text_boto=Trametre%20pagament&order=12345&url_ok=https://ok.html"
                      width="100%"
                      height="100%"
                      frameBorder="0"
                    ></iframe>
                  </div>
                </div>}



              <div class="profile-div">
                <img id="img_perfil" src={require("./images/imatge_perfil.jpg")} alt="Imatge de perfil"></img>
                <div>
                  <h2 id="nom_alumne">Nom de l'estudiant</h2>
                  <p id="versio">Versió del software: X.X.X</p>
                  <p id="niub_alumne">Niub: XXXXXXXX</p>
                  <div id="fiançaDIV" hidden> /*per fer-lo visible s'ha d'afegir a la classe "tooltip"*/
                    <br /><button className="pagar-fianca-button" onClick={pagarFiança}>
                      Pagar fiança 100€
                    </button>
                    <span class="tooltiptext">Se't sol·licita pagar una fiança de 100€ pel curs XXX. La fiança se't retornarà a la fi del curs.</span>
                  </div>

                </div>
              </div>




              <button id="btn_area_profes" class="button-area-professors" style={{ 'position': 'absolute', 'top': '10px', 'right': '10px' }} onClick={() => changePagina("profes")}>Àrea de professors</button>
              <br /><br />
              <div id="taula_percentatges" ></div>
              <br /><br />




              <div id="flip-card" class="flip-card">
                <div class="flip-card-inner">
                  <div class="flip-card-front">
                    <img id="fr_img" class="fr_img_class"></img>
                    <button id="flip" class="flipButton"><img src={require("./images/arrow.png")} height="80px"></img></button>
                    <div class="name_container">
                      <b id="student_name" class="name"></b>
                    </div>
                    <div class="caducitat_container">
                      <b id="caducitat_val" class="caducitat_v"></b>
                    </div>
                  </div>
                  <div class="flip-card-back">
                    <img id="bk_img" class="bk_img_class"></img>
                    <button id="flip" class="flipButton"><img src={require("./images/arrow.png")} height="80px"></img></button>
                    <div class="codigo_barras">
                      <svg id="barcode" class="br_code"></svg>
                    </div>
                  </div>
                </div>
              </div>


              <div id="capture_carnet" hidden>
                <div class="flip-card-inner">
                  <div>
                    <img id="fr_img2" class="fr_img_class"></img>
                    <div class="name_container">
                      <b id="student_name2" class="name"></b>
                    </div>
                    <div class="caducitat_container">
                      <b id="caducitat_val2" class="caducitat_v"></b>
                    </div>
                  </div>
                  <div>
                    <img id="bk_img2" class="bk_img_class"></img>
                    <div class="codigo_barras">
                      <svg id="barcode" class="br_code"></svg>
                    </div>
                  </div>
                </div>
              </div>



              <button id="button_descarregar_carnet" style={{ display: 'block', margin: '0 auto', marginBottom: '50px', width: '65px', height: '55px' }}
                onClick={descarregarCarnet}><img style={{ width: '50px', height: '50px' }} src={require("./images/download_icon.png")} alt="download" /></button>


              <div class="button-container-inline">
                <button class="button" id="boto_cursActual" onClick={show_cursActual}>CURS ACTUAL</button>
                <button class="button" id="boto_cursosAnteriors" onClick={show_cursosAnteriors}>CURSOS ANTERIORS</button>
              </div>
              <br />
              <div id="contenidor1" style={{ 'overflow-x': 'auto' }}></div>
              <br />
              <div id="contenidor2" style={{ 'overflow-x': 'auto' }}></div>
              <br />
              <div id="contenidor3" style={{ 'overflow-x': 'auto' }}></div>
              <div id="loading_div">
                <div class="loading-overlay">
                  <div class="loading-message">

                    <img src={require("./images/wait.gif")} alt="Sandclock" style={{ height: '35vh' }}></img>
                  </div>
                </div>
              </div>

              {pagina == "expedient" && peticio()}
              <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
              <img id="footer_resultat_expedient" src={require("./images/footer.jpg")} alt="footer"></img>
            </div>
          }


          {(pagina == "profes") &&
            <div id="profes_div" className="mt-5" style={{ width: '99vw', height: '100vh' }}>
              <img id="banner_resultat" src={require("./images/banner.jpg")} alt="banner" hidden></img>
              <br /><br />
              <h1 id="nom_alumne" style={{ 'position': 'absolute', 'top': '0px', 'left': '10px', 'color': 'black' }}></h1>
              <p id="versio" style={{ 'position': 'absolute', 'top': '12px', 'left': '47%', 'color': 'black' }}></p>
              <button id="btn_area_alumnes" class="button-area-professors" style={{ 'position': 'absolute', 'top': '10px', 'right': '10px' }} onClick={() => changePagina("expedient")}>Tornar a l'expedient</button>
              <br /><br />
              <br />
              <div id="contenidor11" style={{ 'overflow-x': 'auto' }}></div>
              <br />

              <div id="loading_div2">
                <div class="loading-overlay">
                  <div class="loading-message">

                    <img src={require("./images/wait.gif")} alt="Sandclock" style={{ height: '35vh' }}></img>
                  </div>
                </div>
              </div>

              {pagina == "profes" && peticioProfe()}
              <br /><br /><br /><br /><br /><br /><br /><br /><br />
              <button id="btn_desar_notes" class="button-save-notes" onClick={() => desarNotes()}>Desar notes</button>
              <br />
              <button style={{ marginBottom: '50px', marginLeft: '5px', width: '65px', height: '55px' }} hidden><img style={{ width: '50px', height: '50px' }} src={require("./images/xls.png")} alt="descarregar excel" /><span>Generar Excel</span></button>

              <br /><br />
              <img id="footer_resultat_profes" style={{ width: '99vw' }} src={require("./images/footer.jpg")} alt="footer"></img>
            </div>
          }






        </section>
      </main >
    </>
  )
}



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);


export default App;


